import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * Кастомный свитчер
 */
export const CustomSwitch = styled((props: SwitchProps) => (
  <Switch
    inputProps={{ 'aria-label': 'контролируемый' }}
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 60,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(28.1px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#D3F85A',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#0C1027',
      border: '4px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#0C1027',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 32,
    height: 32,
    border: '2px solid rgba(255, 255, 255, 0.12)',
    color: '#0C1027',
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px',
    width: 58,
    height: 32,
    backgroundColor: '#1E254A',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
