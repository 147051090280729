import { useEffect, useRef, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { captureEvent } from '@/utils/analytics';

/**
 * Хук для отслеживания прокрутки до конца страницы
 * @param isAuthorized - флаг авторизации пользователя
 * @returns объект с ref для элемента, до которого нужно отследить прокрутку
 */
export const useFooterTracking = (isAuthorized: boolean) => {
  const [footerShowed, setFooterShowed] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !footerShowed) {
          setFooterShowed(true);
          track('Flag footer showed', {
            auser: isAuthorized || false,
          });
          captureEvent('Flag footer showed', {
            auser: isAuthorized || false,
          });
        }
      },
      { threshold: 0.1 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [footerShowed, isAuthorized]);

  return { targetRef };
}; 