import trushIcon from '@/assets/icons/case_filter/trushIcon.svg';
import { CustomTextField } from '@/components/styled/CustomTextField';
import { CustomSwitch } from '@/components/styled/CustomSwitch';
import { MpLootboxesFilterModel } from '@/generated/projectlb-hasura';
import { track } from '@amplitude/analytics-browser';
import { IconButton, SelectChangeEvent, Slider } from '@mui/material';
import { filterPriceAtom } from 'atoms/filter-price-atom';
import { menuAtom } from 'atoms/menu-height-atom';
import { weaponTypesAtom } from 'atoms/weapon-types-atom';
import { useAtom, useAtomValue } from 'jotai';
import Image from 'next/image';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FilterMenuItem, FilterSelect } from '../FilterSelect/FilterSelect';
import styles from './Filters.module.scss';
import { captureEvent } from '@/utils/analytics';
import { useBalance } from '@/hooks/use-balance';
import cn from 'classnames';
import { useAuthContext } from '@/hooks/use-auth-context';

interface FiltersProps {
  initialFilter: MpLootboxesFilterModel;
  categoriesNames: { name: string; anchor: string }[];
}

const InputPriceProps = {
  disableUnderline: true,
  inputProps: {
    style: {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0px',
      verticalAlign: 'middle',
      padding: '0',
      height: '16px',
    },
  },
};

const trackFilterEvent = (subevent: string, value: unknown) => {
  track('filter_case', { subevent, value });
  captureEvent('filter_case', { subevent, value });
};

const Filters = ({ initialFilter, categoriesNames }: FiltersProps) => {
  const { height: scrollOffset } = useAtomValue(menuAtom);
  const [checked, setChecked] = useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [isPriceHighlighted, setIsPriceHighlighted] = useState(false);
  const { balance } = useBalance();
  const { isAuthorized } = useAuthContext();
  
  // Подготовка типов оружия с добавлением опции "ВСЕ"
  const weaponTypes = useMemo(() => {
    const types = [...initialFilter.filter_weapon_types];
    types.unshift({
      name: 'ВСЕ ОРУЖИЯ',
      param: 'all',
    });
    return types;
  }, [initialFilter.filter_weapon_types]);

  const minPriceValue = initialFilter.min_price;
  const maxPriceValue = initialFilter.max_price;
  
  const [price, setPrice] = useAtom(filterPriceAtom);
  const [selectedWeapons, setSelectedWeapons] = useAtom(weaponTypesAtom);

  const scrollToFunc = useCallback(() => {
    setTimeout(() => {
      const caseFilter = document.querySelector("#case-filter") as HTMLElement;
      const getAnchor = window.location.hash;
      if (caseFilter && getAnchor.length <= 0) {
        window.scrollTo({
          top: caseFilter.offsetTop - 16,
          behavior: 'smooth'  
        });
      }
    }, 100);
  }, []);

  const handleFiltersToggle = useCallback(() => {
    setIsFiltersVisible(!isFiltersVisible);
    scrollToFunc();
  }, [isFiltersVisible]);

  const handleSwitchChange = useCallback(() => {
    setChecked(!checked);
    if(isAuthorized) {
      trackFilterEvent('enough_funds', !checked);
      setPrice([minPriceValue, !checked
        ? Math.min(balance / 100, maxPriceValue)
        : maxPriceValue]);
    } else {
      setPrice([minPriceValue, maxPriceValue]);
    }

    setIsPriceHighlighted(true);
    setTimeout(() => setIsPriceHighlighted(false), 1000);
    scrollToFunc();
  }, [checked, balance, minPriceValue, maxPriceValue]);

  const resetUrl = useCallback(() => {  
    const newURL = window.location.href.split('#')[0];
    window.history.replaceState(null, '', newURL);
  }, []);

  const resetFilters = useCallback(() => {
    resetUrl();
    
    const defaultPrice = [minPriceValue, maxPriceValue];
    setPrice(defaultPrice);
    setSelectedWeapons([weaponTypes[0].param]);
    setChecked(false);
    trackFilterEvent('reset', true);
    scrollToFunc();
  }, [minPriceValue, setPrice, setSelectedWeapons, weaponTypes]);

  const handlePriceChange = useDebouncedCallback((newValue: number[], isMin: boolean) => {
    trackFilterEvent(isMin ? 'price_min' : 'price_max', isMin ? newValue[0] : newValue[1]);
    setPrice(newValue);
    resetUrl();
    scrollToFunc();
  }, 300);

  const handleSliderPriceChange = useCallback((e: Event, newValue: number | number[]) => {
    const values = newValue as number[];
    const isMinChanged = values[0] !== price[0];
    setPrice(values);
    handlePriceChange(values, isMinChanged);
    setChecked(false);
    resetUrl();
    scrollToFunc();
  }, [handlePriceChange, price]);

  const handleInputPriceChange = useCallback((
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isMin: boolean
  ) => {
    const inputValue = Number(event.target.value) || 0;
    const newValues = isMin 
      ? [inputValue, price[1]]
      : [price[0], inputValue];
      
    setPrice(newValues);
    handlePriceChange(newValues, isMin);

    setChecked(false);
    resetUrl();
    scrollToFunc();
  }, [handlePriceChange, price]);

  const handleWeaponSelect = useCallback((event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];

    if (value.length > 1 && value.includes('all')) {
      const filteredValue = value.filter(item => item !== 'all');
      trackFilterEvent('weapon', filteredValue);
      setSelectedWeapons(filteredValue);
      return;
    }

    if (value.length === 0) {
      const defaultValue = [weaponTypes[0].param];
      trackFilterEvent('weapon', defaultValue);
      setSelectedWeapons(defaultValue);
      return;
    }

    trackFilterEvent('weapon', value);
    setSelectedWeapons(value);
    resetUrl();
  }, [setSelectedWeapons, weaponTypes]);

  const handleCategoryChange = useCallback((event: SelectChangeEvent<unknown>) => {
    const anchor = event.target.value as string;
    trackFilterEvent('category', anchor);

    const baseUrl = window.location.href.split('#')[0];
    const newUrl = `${baseUrl}#${anchor}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    const targetElement = document.querySelector(`#${anchor}`) as HTMLElement;
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 16,
        behavior: 'smooth',
      });
    }
  }, [scrollOffset]);

  return (
    <div className={styles.container}>
      <div className={cn(styles.container_filters, styles.container_filters_top)}>
        <div 
          className={styles.container_filters_title}
          onClick={handleFiltersToggle}
          style={{ cursor: 'pointer' }}
        >
          <div className={cn(
            styles.container_filters_title_text,
            isFiltersVisible && styles.active
          )}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <use href="/icons/filters/filters.svg#icon" />
            </svg>
            Фильтр
          </div>
          <div className={styles.container_filters_enough_money} onClick={(e) => {
            e.stopPropagation();
            handleSwitchChange();
          }}>
            <CustomSwitch 
              checked={checked} 
              aria-label="Показать только кейсы с достаточным балансом"
              className={styles.container_filters_enough_money_switch}
              sx={{
                '&.MuiSwitch-root': {
                  width: 34,
                  height: 24,
                },
                '& .MuiSwitch-switchBase': {
                  backgroundColor: '#252E5C',
                  transform: 'translate(2px, 2px)',
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                  backgroundColor: '#252E5C',
                  transform: 'translate(12px, 2px)',
                },
                '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
                  backgroundColor: '#FFFFFF',
                  width: 20,
                  height: 20,
                },
                '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
                  backgroundColor: '#D3F85A',
                },
                '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                  backgroundColor: 'rgba(255, 255, 255, 0.06)',
                  width: 34,
                  height: 24,
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: 'rgba(255, 255, 255, 0.06)',
                  width: 34,
                  height: 24,
                },
              }}
            />
            <span className={styles.container_filters_enough_money_text}>
              Достаточно средств
            </span>
          </div>
        </div>
      </div>
      <div className={cn(
        styles.container_filters, 
        styles.container_filters_bottom,
        isFiltersVisible && styles.container_filters_bottom_visible
      )}>
        <div className={styles.container_filters_bottom_content}>
          <FilterSelect
            multiple
            value={selectedWeapons}
            className={styles.container_filters_autocomplete}
            onChange={handleWeaponSelect}
          >
            {weaponTypes.map((type, index) => (
              <FilterMenuItem 
                key={type.param} 
                value={type.param} 
                disabled={index === 0}
              >
                {type.name}
              </FilterMenuItem>
            ))}
          </FilterSelect>

          <FilterSelect
            value=""
            className={styles.container_filters_autocomplete}
            onChange={handleCategoryChange}
            inputLabel="КАТЕГОРИЯ КЕЙСОВ"
          >
            {categoriesNames.map((category) => (
              <FilterMenuItem key={category.anchor} value={category.anchor}>
                {category.name}
              </FilterMenuItem>
            ))}
          </FilterSelect>
          <p className={styles.container_filters_main_price_title}>
            Ценовой диапазон
          </p>
          <div className={styles.container_filters_main_price_container}>
            <div className={styles.container_filters_main_price}>
              <CustomTextField
                variant="standard"
                type="number"
                value={price[0] || ''}
                onChange={(e) => handleInputPriceChange(e, true)}
                InputProps={InputPriceProps}
              />
            </div>
            <div className={styles.container_filters_main_price_separator}>
              -
            </div>
            <div className={cn(
              styles.container_filters_main_price,
              isPriceHighlighted && styles.container_filters_main_price_highlighted
            )}>
              <CustomTextField
                variant="standard"
                type="number"
                value={price[1] || ''}
                onChange={(e) => handleInputPriceChange(e, false)}
                InputProps={InputPriceProps}
              />
            </div>
          </div>
          <div className={styles.container_filters_main_price_slider}>
            <Slider
              size="medium"
              step={5}
              min={minPriceValue}
              max={maxPriceValue}
              value={price}
              onChange={handleSliderPriceChange}
              className={styles.container_filters_main_price_slider_line}
              sx={{
                '& .MuiSlider-rail': {
                  background: '#252E5C',
                  opacity: 1,
                },
                '& .MuiSlider-thumb': {
                  width: 24,
                  height: 24,
                }
              }}
            />
          </div>
        </div>

        <div className={styles.container_filters_resetBtn}>
          <IconButton 
            className={styles.container_resetBtn} 
            onClick={resetFilters}
            aria-label="Сбросить фильтры"
          >
            <Image src={trushIcon} alt="Сбросить" />
            <p className={styles.container_resetBtn_text}>Сбросить</p>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Filters;
